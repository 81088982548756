import "./App.css";

function App() {
  return (
    <div className="app">
      <img src="/richard.jpg" alt="Richard Sokolowski" className="richard" />
      <p className="text">
        Uncle Richard Sokolowski and his nephew Harley Barley circa 2022
      </p>
    </div>
  );
}

export default App;
